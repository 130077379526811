import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { User } from '@models/users/user.model';
import { Asset, createDataUrl, emptyAsset } from '@models/shared/asset.model';
export const IMAGE_MEDIA_TYPES = 'image/x-png,image/jpeg,image/gif';
@Component({
  selector: 'app-user-avatar',
  standalone: true,
  imports: [CommonModule, MatTooltipModule],
  templateUrl: './user-avatar.component.html',
  styleUrl: './user-avatar.component.scss',
})
export class UserAvatarComponent {
  @Input() user?: User;
  @Input() email?: string;
  @Input() phone?: string;
  @Input() size = 45;
  @Input() placeholderFontSize = 18;
  @Input() backgroundColor?: string = 'white';
  @Input() withName?: boolean = false;
  @Input() isUploader?: boolean = false;
  @Input() nameTooltip?: boolean = false;
  @Input() isVotingView?: boolean = false;
  @Input() fallbackInitials?: string;
  @Input() borderRadius?: string = '50%';
  @Input() showBoxShadow = false;
  @Input() isActive?: boolean = false;
  @Output()
  updateAvatar = new EventEmitter<Asset | undefined>();
  fileTypes = IMAGE_MEDIA_TYPES;

  private readonly base64Prefix = 'base64,';

  uploadAvatar(event: Event) {
    if (!this.isUploader) {
      return;
    }
    const target = event.target as HTMLInputElement;
    const avatar = target.files as FileList;
    this.handleFileChange(avatar[0]);
  }

  private handleFileChange(file: File) {
    if (!this.user) {
      return;
    }

    if (!file) {
      this.updateAvatar.emit(undefined);
      this.user.avatarUrl = '';
      return;
    }

    const avatarAsset = emptyAsset();

    avatarAsset.name = file ? file.name : '';

    if (file && file.type) {
      avatarAsset.mimeType = file.type;
    }

    this.getBase64(file).then((base64: string | ArrayBuffer | null) => {
      if (!base64 || !avatarAsset) {
        return;
      }

      base64 = base64.toString();

      base64 = base64.substring(
        base64.indexOf(this.base64Prefix) + this.base64Prefix.length,
      );

      avatarAsset.base64 = base64;

      this.updateAvatar.emit(avatarAsset);
      this.user!.avatarUrl = createDataUrl(avatarAsset);
    });
  }

  get tooltipText(): string {
    if (this.isVotingView) {
      return this.nameTooltip ? this.user?.getInitials() || '' : '';
    } else {
      return this.nameTooltip ? this.user?.name || '' : '';
    }
  }

  private getBase64(file: File) {
    return new Promise<string | ArrayBuffer | null>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }
}
